import * as React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { withStores } from "stores";

import { DiscountCodeBannerProps } from "../typings";
import { getDiscountCode, getURLQueryParam } from "./utils/discountCodeUtils";
import { ErrorBanner } from "./ErrorBanner";
import { ValidBanner } from "./ValidBanner";
import { actSetDisplayBanner, useDiscountCodeBannerReducer } from "./utils/reducer";
import { DiscountCodeModel } from "./utils/discountCodeTypes";
import { updateDiscountCodeState } from "./utils/discountCodeApi";
import { FAILED_HOTW_DISCOUNT_CODE_FETCH } from "src/config/systemEvents";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { BannerPlaceholder } from "../BannerPlaceholder";

export const DiscountCodeBanner = withStores(
  "context", // unused but needed to satisfy ComponentProps requirements
  "flexModuleModelStore",
  "hotwireConfig"
)(
  observer(
    ({
      hotwireConfig: { config, logger },
      context,
      supportedVerticals,
      displayOtherBanner,
      discountBannerColor,
      id,
      validMarketingBannerConfig,
    }: DiscountCodeBannerProps) => {
      const [state, dispatch] = useDiscountCodeBannerReducer();
      const history = useHistory();
      const initialTimeRef = { endTimestamp: Date.now(), timerId: -1 };
      const timeRef = React.useRef(initialTimeRef);
      const { formatText } = useLocalization();

      React.useEffect(() => {
        let mounted = true;
        const dccidValue = getURLQueryParam("dccid", history.location.search);
        getDiscountCode(dccidValue, config)
          .then((model: DiscountCodeModel) => {
            if (mounted) {
              updateDiscountCodeState(
                model,
                displayOtherBanner,
                context.currency,
                timeRef,
                formatText,
                dispatch,
                supportedVerticals
              );
            }
          })
          .catch((error: Error) => {
            if (mounted) {
              dispatch(actSetDisplayBanner(true, formatText("hotwire.discount-code-banner.error.api")));
            }
            logger.logEvent(FAILED_HOTW_DISCOUNT_CODE_FETCH, error);
          });

        return function cleanup() {
          mounted = false;
        };
      }, []);

      const { displayBanner, errorMessage, timeLeft, discountAmount, minimumSpendLabel, verticalLabel } = state;
      const displayValidBanner = errorMessage ? (
        <ErrorBanner errorMessage={errorMessage} />
      ) : (
        <ValidBanner
          countdownTime={timeLeft}
          discountAmount={discountAmount}
          discountBannerColor={discountBannerColor}
          minimumSpendLabel={minimumSpendLabel}
          verticalLabel={verticalLabel}
          validMarketingBannerConfig={validMarketingBannerConfig}
        />
      );

      const showBannerPlaceholder = validMarketingBannerConfig ? <BannerPlaceholder /> : null;

      return (
        <EGDSSpacing className="HotwireMarketingBanner" margin={{ blockstart: "three" }}>
          <div id={id}>{displayBanner ? displayValidBanner : showBannerPlaceholder}</div>
        </EGDSSpacing>
      );
    }
  )
);

export default DiscountCodeBanner;
