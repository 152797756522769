import * as React from "react";

import {
  HotwireMarketingBannerPlacementCode,
  HotwireMarketingBannerProps,
  HotwireMarketingBannerView,
} from "./typings";
import MessagingCardView from "components/flexComponents/HotwireMarketingBanner/views/MessagingCardView";
import CondensedMessagingCardView from "components/flexComponents/HotwireMarketingBanner/views/CondensedMessagingCardView";
import DiscountCodeBanner from "./discountCode/DiscountCodeBanner";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { isBannerConfiguredCorrectly } from "./utils/HotwireMarketingBanner";

const getViewComponent = (view?: string) => {
  return view === HotwireMarketingBannerView.CONDENSED ? CondensedMessagingCardView : MessagingCardView;
};

export const HotwireMarketingBanner = withStores("flexModuleModelStore")(
  observer((props: HotwireMarketingBannerProps) => {
    const { templateComponent } = props;
    const {
      metadata: { id },
      config,
    } = templateComponent;

    if (!config) {
      return null;
    }
    const { headline, subheadline, linkOneText, view } = config;
    const { placement = HotwireMarketingBannerPlacementCode.TOP } = config;
    const initialDisplayBannerState = placement === HotwireMarketingBannerPlacementCode.BELOW_HERO;
    const [displayMarketingBanner, setDisplayMarketingBanner] = React.useState(initialDisplayBannerState);

    const setMarketingBannerDisplay = (status: boolean) => setDisplayMarketingBanner(status);
    const ViewComponent = getViewComponent(view);
    const validBannerConfig = isBannerConfiguredCorrectly(headline, subheadline, linkOneText);

    return displayMarketingBanner && validBannerConfig ? (
      <ViewComponent {...config} id={id} />
    ) : (
      <DiscountCodeBanner
        {...config}
        displayOtherBanner={setMarketingBannerDisplay}
        id={id}
        validMarketingBannerConfig={validBannerConfig}
      />
    );
  })
);

export default HotwireMarketingBanner;
