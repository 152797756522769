import * as React from "react";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSLineSkeleton } from "@egds/react-core/skeleton";

export const BannerPlaceholder = () => {
  return (
    <EGDSCard padded border>
      <EGDSCardContentSection>
        <EGDSLineSkeleton animation />
      </EGDSCardContentSection>
    </EGDSCard>
  );
};
