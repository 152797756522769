import { ExtendedTemplateComponent, ExtendedTemplateComponentConfig } from "src/stores/ExperienceTemplateStore/typings";
import { FlexModuleModelStore } from "stores/FlexModuleModelStore";
import { HotwireConfigStore } from "stores/HotwireConfigStore";
import { ComponentProps } from "typings/flexFramework/FlexComponent";

export enum HotwireMarketingBannerBgColors {
  WHITE = "white",
  RED = "red",
  TEAL = "teal",
}

export enum HotwireMarketingBannerView {
  CONDENSED = "condensed",
  DEFAULT = "default",
}

export enum HotwireMarketingBannerPlacementCode {
  TOP = "top",
  BELOW_HERO = "below_hero",
}

export interface HotwireMarketingBannerTemplateComponentConfig extends ExtendedTemplateComponentConfig {
  headline: string;
  subheadline: string;
  mark: string;
  markAlternateText: string;
  linkOneText: string;
  linkOneHref: string;
  backgroundColor: HotwireMarketingBannerBgColors;
  showIcon: boolean;
  placement?: string;
  supportedVerticals?: string[];
  discountBannerColor: HotwireMarketingBannerBgColors;
  id: string;
}

export interface HotwireMarketingBannerTemplateComponent extends ExtendedTemplateComponent {
  config: HotwireMarketingBannerTemplateComponentConfig;
}

export interface HotwireMarketingBannerProps extends ComponentProps {
  templateComponent: HotwireMarketingBannerTemplateComponent;
  flexModuleModelStore: FlexModuleModelStore;
}

export interface DiscountCodeBannerProps extends ComponentProps {
  flexModuleModelStore: FlexModuleModelStore;
  hotwireConfig: HotwireConfigStore;
  displayOtherBanner: (status: boolean) => void;
  supportedVerticals?: string[];
  discountBannerColor: HotwireMarketingBannerBgColors;
  id: string;
  validMarketingBannerConfig: boolean;
}
