import { HotwireMarketingBannerBgColors } from "../typings";

const isWhiteBackground = (backgroundColor: HotwireMarketingBannerBgColors) => {
  return backgroundColor === HotwireMarketingBannerBgColors.WHITE;
};

export const getLinkTheme = (backgroundColor: HotwireMarketingBannerBgColors) => {
  return isWhiteBackground(backgroundColor) ? "default" : "inverse";
};

export const shouldRenderBorder = isWhiteBackground;

export const isBannerConfiguredCorrectly = (headline?: string, subheadline?: string, linkOneText?: string) =>
  Boolean(headline || subheadline || linkOneText);
