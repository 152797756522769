import * as React from "react";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSIcon } from "@egds/react-core/icons";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSHeading } from "@egds/react-core/text";

interface ErrorBannerProps {
  errorMessage: string;
}

export const ErrorBanner = ({ errorMessage }: ErrorBannerProps) => (
  <EGDSCard padded className="HotwireDiscountCodeBanner error">
    <EGDSLayoutFlex alignItems="center" space="three">
      <EGDSLayoutFlexItem>
        <EGDSSpacing margin={{ inlinestart: "three" }}>
          <EGDSIcon name="error" />
        </EGDSSpacing>
      </EGDSLayoutFlexItem>
      <EGDSLayoutFlexItem>
        <EGDSHeading size={7} tag="h2">
          {errorMessage}
        </EGDSHeading>
      </EGDSLayoutFlexItem>
    </EGDSLayoutFlex>
  </EGDSCard>
);
