import * as React from "react";

import { EGDSIcon } from "@egds/react-core/icons";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSLink } from "@egds/react-core/link";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";

import { getLinkTheme, shouldRenderBorder } from "../utils/HotwireMarketingBanner";

import { HotwireMarketingBannerBgColors, HotwireMarketingBannerTemplateComponentConfig } from "../typings";

export const CondensedMessagingCardView: React.FC<HotwireMarketingBannerTemplateComponentConfig> = ({
  backgroundColor = HotwireMarketingBannerBgColors.WHITE,
  showIcon,
  mark,
  headline,
  subheadline,
  linkOneHref,
  linkOneText,
  id,
}) => (
  <EGDSSpacing className="HotwireMarketingBanner" margin={{ blockstart: "three" }}>
    <div id={id}>
      <EGDSCard border={shouldRenderBorder(backgroundColor)} className={backgroundColor} padded>
        <EGDSLayoutFlex alignItems="center" space="three">
          {showIcon && (
            <EGDSLayoutFlexItem>
              <EGDSSpacing margin={{ inlinestart: "three" }}>
                <EGDSIcon name={mark} />
              </EGDSSpacing>
            </EGDSLayoutFlexItem>
          )}
          <EGDSLayoutFlexItem>
            <div>
              <EGDSLayoutFlex space="two" wrap="wrap">
                {headline && (
                  <EGDSLayoutFlexItem>
                    <EGDSText inline>
                      <EGDSHeading size={7} tag="h2">
                        {headline}
                      </EGDSHeading>
                    </EGDSText>
                  </EGDSLayoutFlexItem>
                )}
                {subheadline && (
                  <EGDSLayoutFlexItem>
                    <EGDSText
                      inline
                      size={300}
                      theme={backgroundColor === HotwireMarketingBannerBgColors.WHITE ? "default" : "inverse"}
                    >
                      {` ${subheadline} `}
                    </EGDSText>
                  </EGDSLayoutFlexItem>
                )}
                <EGDSLayoutFlexItem>
                  <EGDSLink theme={getLinkTheme(backgroundColor)} inline>
                    <a href={linkOneHref}>{linkOneText}</a>
                  </EGDSLink>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </div>
          </EGDSLayoutFlexItem>
        </EGDSLayoutFlex>
      </EGDSCard>
    </div>
  </EGDSSpacing>
);
export default CondensedMessagingCardView;
