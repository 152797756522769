import * as React from "react";

import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { EGDSLink } from "@egds/react-core/link";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSIllustrations } from "@egds/react-core/illustrations";

import { getLinkTheme, shouldRenderBorder } from "../utils/HotwireMarketingBanner";

import { HotwireMarketingBannerBgColors, HotwireMarketingBannerTemplateComponentConfig } from "../typings";

export const MessagingCardView: React.FC<HotwireMarketingBannerTemplateComponentConfig> = ({
  backgroundColor = HotwireMarketingBannerBgColors.WHITE,
  showIcon,
  mark,
  markAlternateText,
  headline,
  subheadline,
  linkOneHref,
  linkOneText,
  id,
}) => {
  const bannerLinkBlockStart = headline ? "two" : "unset";
  return (
    <EGDSSpacing className="HotwireMarketingBanner" margin={{ blockstart: "three" }}>
      <div id={id}>
        <EGDSCard border={shouldRenderBorder(backgroundColor)} className={backgroundColor} padded>
          <EGDSLayoutFlex alignItems="center" space="three">
            {showIcon && (
              <Viewport>
                <ViewSmall />
                <ViewMedium>
                  <EGDSLayoutFlexItem>
                    <EGDSIllustrations alt={markAlternateText} url={mark} />
                  </EGDSLayoutFlexItem>
                </ViewMedium>
              </Viewport>
            )}
            <EGDSLayoutFlexItem>
              <div>
                {headline && (
                  <EGDSHeading tag="h2" size={7}>
                    {headline}
                  </EGDSHeading>
                )}
                {subheadline && (
                  <EGDSText
                    size={300}
                    theme={backgroundColor === HotwireMarketingBannerBgColors.WHITE ? "default" : "inverse"}
                  >
                    {subheadline}
                  </EGDSText>
                )}
                <EGDSSpacing margin={{ blockstart: bannerLinkBlockStart }}>
                  <EGDSLink theme={getLinkTheme(backgroundColor)} inline>
                    <a href={linkOneHref}>{linkOneText}</a>
                  </EGDSLink>
                </EGDSSpacing>
              </div>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </EGDSCard>
      </div>
    </EGDSSpacing>
  );
};
export default MessagingCardView;
