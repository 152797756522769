import * as React from "react";

import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { useLocalization } from "@shared-ui/localization-context";

import { EGDSCard } from "@egds/react-core/cards";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { Countdown } from "./utils/discountCodeTypes";
import { FlameIcon } from "./FlameIcon";
import { COUNTER_TIME_INTERVAL, INITIAL_COUNTDOWN_TIME_TEXT } from "./utils/constants";
import { HotwireMarketingBannerBgColors } from "../typings";
import { BannerPlaceholder } from "../BannerPlaceholder";

interface ValidBannerProps {
  countdownTime: Countdown;
  discountAmount: string;
  discountBannerColor: string;
  minimumSpendLabel: string;
  verticalLabel: string;
  validMarketingBannerConfig: boolean;
}
const zeroPad = (timeUnit: number) => `${timeUnit < 10 ? "0" : ""}${timeUnit}`;

export const ValidBanner = ({
  countdownTime,
  discountAmount,
  discountBannerColor,
  minimumSpendLabel,
  verticalLabel,
  validMarketingBannerConfig,
}: ValidBannerProps) => {
  const { days, hours, minutes, seconds } = countdownTime;
  const countDownTimeText = `${hours}h:${zeroPad(minutes)}m:${zeroPad(seconds)}s`;
  const hideCountDownTime = countDownTimeText === INITIAL_COUNTDOWN_TIME_TEXT && days === 0;
  const { formatText } = useLocalization();
  const header = formatText("hotwire.discount-code-banner.heading", discountAmount);
  const endsIn = formatText("hotwire.discount-code-banner.subheading.endsIn");
  const daysText = formatText("hotwire.discount-code-banner.subheading.days");
  const dayText = formatText("hotwire.discount-code-banner.subheading.day");

  const textTheme = discountBannerColor === HotwireMarketingBannerBgColors.WHITE ? "default" : "inverse";
  const [showBanner, setShowBanner] = React.useState(false);

  const enableShowBannerFlag = () => {
    setTimeout(() => {
      setShowBanner(!showBanner);
    }, COUNTER_TIME_INTERVAL);
  };
  !showBanner && enableShowBannerFlag();

  return showBanner ? (
    <EGDSCard border className={`HotwireDiscountCodeBanner ${discountBannerColor}`} padded>
      <EGDSLayoutFlex alignItems="center" space="three">
        <Viewport>
          <ViewSmall />
          <ViewMedium>
            <EGDSLayoutFlexItem>
              <FlameIcon discountBannerColor={discountBannerColor} />
            </EGDSLayoutFlexItem>
          </ViewMedium>
        </Viewport>
        <EGDSLayoutFlexItem>
          <div>
            <EGDSHeading size={7} tag="h2" className="HotwireDiscountCodeBanner--Heading">
              {header}
              <EGDSText inline="sup">
                <EGDSText inline size={100} theme={textTheme}>
                  {"\u00AE"}
                </EGDSText>
              </EGDSText>{" "}
              {`${verticalLabel}${minimumSpendLabel}.`}
            </EGDSHeading>
            {!hideCountDownTime && (
              <>
                <EGDSText inline size={300} theme={textTheme}>
                  {` ${endsIn} ${days ? `${days} ${days > 1 ? daysText : dayText} ` : ""} `}
                </EGDSText>
                <EGDSHeading size={7} tag="h2" className="HotwireDiscountCodeBanner--Timer">
                  <span>{countDownTimeText}</span>
                </EGDSHeading>
              </>
            )}
          </div>
        </EGDSLayoutFlexItem>
      </EGDSLayoutFlex>
    </EGDSCard>
  ) : validMarketingBannerConfig ? (
    <BannerPlaceholder />
  ) : null;
};
