import { useReducer } from "react";
import { DEFAULT_COUNTDOWN_TIME_OBJ } from "./constants";
import { Countdown } from "./discountCodeTypes";

/*
 * Types
 */

export interface DiscountCodeBannerState {
  timeLeft: Countdown;
  displayBanner: boolean;
  errorMessage: string;
  verticalLabel: string;
  minimumSpendLabel: string;
  discountAmount: string;
}

export type DiscountCodeBannerAction =
  | { type: "setTimeLeft"; timeLeft: Countdown }
  | { type: "setDisplayBanner"; displayBanner: boolean; errorMessage: string }
  | {
      type: "setValidDiscountCodeBannerData";
      displayBanner: boolean;
      errorMessage: string;
      verticalLabel: string;
      minimumSpendLabel: string;
      discountAmount: string;
    };

/*
 * Reducer
 */

const reducer = (state: DiscountCodeBannerState, action: DiscountCodeBannerAction): DiscountCodeBannerState => {
  switch (action.type) {
    case "setTimeLeft":
      return {
        ...state,
        timeLeft: action.timeLeft,
      };
    case "setDisplayBanner":
      return {
        ...state,
        displayBanner: action.displayBanner,
        errorMessage: action.errorMessage,
      };
    case "setValidDiscountCodeBannerData":
      return {
        ...state,
        displayBanner: action.displayBanner,
        errorMessage: action.errorMessage,
        verticalLabel: action.verticalLabel,
        minimumSpendLabel: action.minimumSpendLabel,
        discountAmount: action.discountAmount,
      };
  }
};

/*
 * Derived state
 */

export const getDefaultDiscountCodeBannerState = (): DiscountCodeBannerState => ({
  timeLeft: { ...DEFAULT_COUNTDOWN_TIME_OBJ },
  displayBanner: false,
  errorMessage: "",
  verticalLabel: "",
  minimumSpendLabel: "$0",
  discountAmount: "$0",
});

/*
 * Hook
 */

export const useDiscountCodeBannerReducer = () => useReducer(reducer, getDefaultDiscountCodeBannerState());

/*
 * Action creators
 */

export const actSetTimeLeft = (timeLeft: Countdown): DiscountCodeBannerAction => ({ type: "setTimeLeft", timeLeft });
export const actSetDisplayBanner = (displayBanner: boolean, errorMessage: string): DiscountCodeBannerAction => ({
  type: "setDisplayBanner",
  displayBanner,
  errorMessage,
});

export const actSetValidDiscountCodeBannerData = (
  displayBanner: boolean,
  errorMessage: string,
  verticalLabel: string,
  minimumSpendLabel: string,
  discountAmount: string
): DiscountCodeBannerAction => ({
  type: "setValidDiscountCodeBannerData",
  displayBanner,
  errorMessage,
  verticalLabel,
  minimumSpendLabel,
  discountAmount,
});
