import { getCountdownTime, parseDateWithTimeZone } from "./discountCodeUtils";
import { COUNTER_TIME_INTERVAL, SUPPORTED_CURRENCIES, DISCOUNT_CODE_VERTICAL_LABELS } from "./constants";
import {
  actSetDisplayBanner,
  actSetTimeLeft,
  actSetValidDiscountCodeBannerData,
  DiscountCodeBannerAction,
} from "./reducer";
import { DiscountCodeModel, TimerRef } from "./discountCodeTypes";
import { MutableRefObject } from "react";

const setDisplayForOtherBanner = (displayOtherBanner: (status: boolean) => void) => {
  if (typeof displayOtherBanner === "function") {
    displayOtherBanner(true);
  }
};

// start countdown on banner so it shows as a digital clock
const startCounter = (timeRef: MutableRefObject<TimerRef>, dispatch: React.Dispatch<DiscountCodeBannerAction>) => {
  timeRef.current.timerId = window.setInterval(() => {
    dispatch(actSetTimeLeft(getCountdownTimeLeft(timeRef.current.endTimestamp)));
  }, COUNTER_TIME_INTERVAL);
};

const getCountdownSeconds = (endTimestamp: number) => Math.round((endTimestamp - Date.now()) / 1000);
const getCountdownTimeLeft = (endTimestamp: number) => getCountdownTime(getCountdownSeconds(endTimestamp));
const clearTimerInterval = (timeRef: MutableRefObject<TimerRef>) => {
  const timerId = timeRef.current.timerId;
  if (timerId) {
    clearInterval(timerId);
  }
};

export const setDiscountCodeInfo = (
  discountCodeModel: DiscountCodeModel,
  displayOtherBanner: (status: boolean) => void,
  selectedCurrency: string,
  timeRef: MutableRefObject<TimerRef>,
  formatText: (key: string, ...args: any[]) => string,
  dispatch: React.Dispatch<DiscountCodeBannerAction>,
  supportedVerticals?: string[]
) => {
  // show error when currency code of discount code does not match with user selected currency
  const discount = discountCodeModel?.discount;
  let currencyCode = discount.amount.currencyCode;
  if (selectedCurrency !== currencyCode) {
    // TODO: track via ominiture window.hwDtm.page.discountcode, 'error-currency-not-matched'
    dispatch(
      actSetDisplayBanner(true, formatText("hotwire.discount-code-banner.error.currencyNotMatched", currencyCode))
    );
  } else {
    const restriction = discount?.restriction;
    const endTimestamp = parseDateWithTimeZone(restriction.endDateTime).getTime();
    timeRef.current.endTimestamp = endTimestamp;
    currencyCode =
      SUPPORTED_CURRENCIES[restriction.minimumSpend.currencyCode as keyof typeof SUPPORTED_CURRENCIES] || "";
    const minimumSpendLabelText =
      (currencyCode &&
        restriction?.minimumSpend?.amount &&
        ` over ${currencyCode}${restriction.minimumSpend.amount}`) ||
      "";
    const discountAmountText = currencyCode && `${currencyCode}${discount.amount.amount}`;
    const validFor: string = restriction.validFor;
    const verticalLabelText = DISCOUNT_CODE_VERTICAL_LABELS[validFor as keyof typeof DISCOUNT_CODE_VERTICAL_LABELS];
    const shouldDisplayForVerticals = supportedVerticals?.includes(validFor);
    if (shouldDisplayForVerticals && verticalLabelText && getCountdownSeconds(endTimestamp) > 0) {
      dispatch(
        actSetValidDiscountCodeBannerData(true, "", verticalLabelText, minimumSpendLabelText, discountAmountText)
      );
      startCounter(timeRef, dispatch);
      // TODO: track via ominiture window.hwDtm.page.discountcode, 'available'
    } else {
      dispatch(actSetDisplayBanner(false, ""));
      clearTimerInterval(timeRef);

      setDisplayForOtherBanner(displayOtherBanner);
      // TODO: track via ominiture window.hwDtm.page.discountcode, null
    }
  }
};

export const updateDiscountCodeState = (
  discountCodeModel: DiscountCodeModel,
  displayOtherBanner: (status: boolean) => void,
  selectedCurency: string,
  timeRef: MutableRefObject<TimerRef>,
  formatText: (key: string, ...args: any[]) => string,
  dispatch: React.Dispatch<DiscountCodeBannerAction>,
  supportedVerticals?: string[]
) => {
  switch (discountCodeModel.discountCodeStatusCategory) {
    case "INVALID_INPUT":
      // TODO: track via ominiture window.hwDtm.page.discountcode, 'error-invalid-input'
      dispatch(actSetDisplayBanner(true, formatText("hotwire.discount-code-banner.error.invalidCoupon")));
      break;
    case "BUSINESS_ERROR":
      // TODO: track via ominiture window.hwDtm.page.discountcode, 'min-spend-not-reached'
      updateBusinessErrorState(
        discountCodeModel.discountCodeErrorType,
        displayOtherBanner,
        timeRef,
        formatText,
        dispatch
      );
      break;
    case "SUCCESS":
      // TODO: track via ominiture window.hwDtm.page.discountcode, 'discount-applied'
      setDiscountCodeInfo(
        discountCodeModel,
        displayOtherBanner,
        selectedCurency,
        timeRef,
        formatText,
        dispatch,
        supportedVerticals
      );
      break;
    default:
      dispatch(actSetDisplayBanner(false, ""));
      clearTimerInterval(timeRef);

      setDisplayForOtherBanner(displayOtherBanner);
      break;
  }
};

export const updateBusinessErrorState = (
  discountCodeErrorType: string,
  displayOtherBanner: (status: boolean) => void,
  timeRef: MutableRefObject<TimerRef>,
  formatText: (key: string, ...args: any[]) => string,
  dispatch: React.Dispatch<DiscountCodeBannerAction>
) => {
  switch (discountCodeErrorType) {
    case "EXPIRED":
      // TODO: track via ominiture window.hwDtm.page.discountcode, 'error-expired'
      dispatch(actSetDisplayBanner(true, formatText("hotwire.discount-code-banner.error.dealExpired")));
      break;
    case "NOT_VALID_YET":
      // TODO: track via ominiture window.hwDtm.page.discountcode, 'error-notvalidyet'
      dispatch(actSetDisplayBanner(true, formatText("hotwire.discount-code-banner.error.notValidYet")));
      break;
    default:
      dispatch(actSetDisplayBanner(false, ""));
      clearTimerInterval(timeRef);

      setDisplayForOtherBanner(displayOtherBanner);
      break;
  }
};
