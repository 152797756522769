import * as React from "react";
import { EGDSSpacing } from "@egds/react-core/spacing";

import { HotwireMarketingBannerBgColors } from "../typings";

interface FlameIconProps {
  discountBannerColor: string;
}

export const FlameIcon: React.FC<FlameIconProps> = ({ discountBannerColor }) => (
  <EGDSSpacing margin={{ inlinestart: "three" }}>
    {discountBannerColor === HotwireMarketingBannerBgColors.WHITE ? (
      <img alt="" src="https://ak-secure.hotwirestatic.com/current/static/images/lib/hw-banner/icons/hotwire-red.svg" />
    ) : (
      <img
        alt=""
        src="https://ak-secure.hotwirestatic.com/current/static/images/lib/hw-banner/icons/hotwire-white.svg"
      />
    )}
  </EGDSSpacing>
);
