import { Countdown } from "./discountCodeTypes";

export const DISCOUNT_CODE_VERTICALS_LIST = ["ALL", "HOTRATE_HOTEL", "HOTRATE_CAR"];

export enum DISCOUNT_CODE_VERTICAL_LABELS {
  ALL = "All",
  HOTRATE_HOTEL = "Hotels",
  HOTRATE_CAR = "Cars",
}

export enum SUPPORTED_CURRENCIES {
  USD = "$",
  AUD = "AUD",
  EUR = "€",
  CAD = "CAD",
  SEK = "SEK",
  NOK = "NOK",
  DKK = "DKK",
  NZD = "NZD",
  CHF = "CHF",
}

export const DISCOUNT_CODE_ID_COOKIE_KEY = "hwDccId";

export const COUNTER_TIME_INTERVAL = 1000;

export const MILLI_SECONDS_IN_DAY = 86400000;

export const SECONDS_IN_DAY = 86400;
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_DAY = 3600;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;

export const INITIAL_COUNTDOWN_TIME_TEXT = "0h:00m:00s";

export const DEFAULT_COUNTDOWN_TIME_OBJ: Countdown = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

export const DEFAULT_DISCOUNT_CODE_MODEL = {
  discount: {
    restriction: {
      minimumSpend: {
        currencyCode: "",
        amount: 0,
      },
      startDateTime: "",
      endDateTime: "",
      validFor: "",
    },
    amount: { currencyCode: "", amount: 0 },
    discountCode: "",
  },
  discountCodeStatusCategory: "",
  discountCodeErrorType: "",
  discountCodeErrorDesc: "",
};
