import { getCookie, setCookie } from "src/components/utility/cookie";

import { HotwireConfig } from "stores/HotwireConfigStore";
import { fetchHotwireApi, HotwireFetchMethod } from "components/utility/hotwire/HotwireApiUtil";
import { safeResponseToJson } from "src/components/utility/FetchUtils";
import {
  DEFAULT_COUNTDOWN_TIME_OBJ,
  DEFAULT_DISCOUNT_CODE_MODEL,
  DISCOUNT_CODE_ID_COOKIE_KEY,
  HOURS_IN_DAY,
  MILLI_SECONDS_IN_DAY,
  MINUTES_IN_DAY,
  MINUTES_IN_HOUR,
  SECONDS_IN_DAY,
  SECONDS_IN_MINUTE,
} from "./constants";
import { Countdown, RawApiResponse, Discount, DiscountCodeModel } from "./discountCodeTypes";

/**
 * Get countdown time in number presentation.
 * @param {Number} timeInSeconds
 */

export const getCountdownTime = (timeInSeconds: number): Countdown => {
  const countdownTime = { ...DEFAULT_COUNTDOWN_TIME_OBJ };

  // Only calculate if timeInSeconds is positive (number larger than 0).
  if (timeInSeconds > 0) {
    countdownTime.days = Math.floor(timeInSeconds / SECONDS_IN_DAY);
    timeInSeconds -= countdownTime.days * SECONDS_IN_DAY;

    countdownTime.hours = Math.floor(timeInSeconds / MINUTES_IN_DAY) % HOURS_IN_DAY;
    timeInSeconds -= countdownTime.hours * MINUTES_IN_DAY;

    countdownTime.minutes = Math.floor(timeInSeconds / SECONDS_IN_MINUTE) % MINUTES_IN_HOUR;
    timeInSeconds -= countdownTime.minutes * SECONDS_IN_MINUTE;

    countdownTime.seconds = Math.floor(timeInSeconds % SECONDS_IN_MINUTE);
  }

  return countdownTime;
};

export const getURLQueryParam = (queryParam: string, search: string) => {
  const urlQuery = new URLSearchParams(search);
  const value = urlQuery.get(queryParam);

  return value;
};

const getDiscountCodeFromCache = () => {
  const cache = getCookie(DISCOUNT_CODE_ID_COOKIE_KEY);

  const discountCodeModel = cache && JSON.parse(cache);

  return discountCodeModel || {};
};

const getDiscountCodeDataFromAPI = (
  { discountCodeService }: HotwireConfig,
  discountCode: string
): Promise<RawApiResponse> =>
  fetchHotwireApi(discountCodeService, {
    method: HotwireFetchMethod.GET,
    pathParams: discountCode,
  }).then(safeResponseToJson);

export const getDiscountCodeModel = (rawData: RawApiResponse) => {
  const discountCodeModel = {
    ...DEFAULT_DISCOUNT_CODE_MODEL,
  };

  if (!rawData) {
    return discountCodeModel;
  }
  const statusCodeCategory = rawData.getDiscountStatus.statusCodeCategory;
  switch (statusCodeCategory) {
    case "INVALID_INPUT":
      discountCodeModel.discountCodeStatusCategory = statusCodeCategory;
      break;
    case "BUSINESS_ERROR":
      const [{ discountNotValidError }] = rawData.getDiscountStatus.getDiscountErrorCollectionList;
      discountCodeModel.discountCodeStatusCategory = statusCodeCategory;
      discountCodeModel.discountCodeErrorType = discountNotValidError.discountErrorType;
      discountCodeModel.discountCodeErrorDesc = discountNotValidError.descriptionRawText;
      break;
    case "SUCCESS":
      const { discount } = discountCodeModel;
      discount.restriction = {
        minimumSpend: rawData.discount.restriction.minimumSpend,
        startDateTime: rawData.discount.restriction.startDateTime,
        endDateTime: rawData.discount.restriction.endDateTime,
        validFor: rawData.discount.restriction.validFor,
      };

      discount.amount = rawData.discount.amount;
      discount.discountCode = rawData.discount.discountCode;
      discountCodeModel.discountCodeStatusCategory = statusCodeCategory;
      discountCodeModel.discount = discount;
      break;
  }

  return discountCodeModel;
};

export const parseDateWithTimeZone = (dateString: string) => {
  const timeRegex = /(\d{2}:){2}\d{2}/;
  const dateRegex = /\d{2}-[a-zA-Z]{3}-\d{4}/;
  const dateArray: string[] | undefined = dateRegex.exec(dateString)?.[0]?.split("-");
  const timeArray: string[] | undefined = timeRegex.exec(dateString)?.[0]?.split(":");

  const month = new Date(Date.parse(`${dateArray?.[1]} 1, ${new Date().getFullYear()}`)).getMonth();
  const parsedDate = new Date(
    Number(dateArray?.[2]),
    month,
    Number(dateArray?.[0]),
    Number(timeArray?.[0]),
    Number(timeArray?.[1]),
    Number(timeArray?.[2])
  );
  const timezoneOffset = dateString.indexOf("PDT") > 0 ? 420 : 480;

  const offsetInMS = (timezoneOffset - new Date().getTimezoneOffset()) * 60 * 1000;

  parsedDate.setTime(parsedDate.getTime() + offsetInMS);

  return parsedDate;
};

const setDiscountCodeToCache = (discountCodeModel: { discount: Discount }) => {
  const endDate = parseDateWithTimeZone(discountCodeModel.discount.restriction.endDateTime);

  setCookie({
    key: DISCOUNT_CODE_ID_COOKIE_KEY,
    value: JSON.stringify(discountCodeModel),
    days: endDate.getTime() / MILLI_SECONDS_IN_DAY,
  });
};

const fetchDiscountCodeData = (config: HotwireConfig, discountCode: string): Promise<DiscountCodeModel> =>
  getDiscountCodeDataFromAPI(config, discountCode).then((response) => {
    const discountCodeModel: DiscountCodeModel = getDiscountCodeModel(response);
    if (discountCodeModel.discountCodeStatusCategory === "SUCCESS") {
      setDiscountCodeToCache(discountCodeModel);
    }

    return discountCodeModel;
  });

export const getDiscountCode = (discountCode: string | null, config: HotwireConfig): Promise<DiscountCodeModel> => {
  const cachedDiscountCodeModel: DiscountCodeModel = getDiscountCodeFromCache();
  const cachedDiscountCode = cachedDiscountCodeModel?.discount?.discountCode;
  if ((cachedDiscountCode && cachedDiscountCode === discountCode) || (!discountCode && cachedDiscountCode)) {
    // if dccid from url is same as we have in browser cache, no need to call api and just get discount code data from cache and show banner
    // if dccid is not in url, but we have previous unexpired dccid in cache, get discount code data from cache and show banner
    return Promise.resolve(cachedDiscountCodeModel);
  }
  if (discountCode) {
    return fetchDiscountCodeData(config, discountCode);
  }

  // no dccid found in url or cache
  return Promise.resolve(DEFAULT_DISCOUNT_CODE_MODEL);
};
